@charset "utf-8";

.App {
  text-align: center;
}

#root{
  font-size:1.2em;
}

#title-title {
  font-size: 1.1em;
  color: #FFFFFF;
  background: #1961a6;
  padding: 1px 10px;
}

#visuraImmagini fieldset{
  margin:20px 0;
  border:1px solid #1062b0;
  padding:10px 20px;

  legend{
    font-size:1.1em;
    font-weight:bold;
    color:#000;
    span{
      font-size:.9em;
      font-weight:normal;
      color:#707070
    }
  }
  >div{
    margin:16px 0;
  }
  label{
    display: inline-block;
    width: 150px;
  }

  input, select {
  	font-size: 100%;
  	border: 1px solid;
  	border-color: #abadb3 #dbdfe6 #e3e9ef #e2e3ea;
  	padding: 8px;
  	vertical-align: middle;
  	width: 130px;
  }
  input:focus, input:hover, select:focus, select:hover  {
  	border-color: #5794bf #b7d5ea #c7e2f1 #c5daed;
  }

  .bottoni{
    margin-top:20px;
    text-align:right;
    #invia{
      // background:transparent url(img/ricerca.jpg) no-repeat;
      background-color: #4CAF50; /* Green */
      border:none;
      border-radius: 4px;
      color:#fff;
      font-weight:bold;
      text-align: center;
      text-decoration: none;
      padding: 15px auto;
      display: inline-block;
      font-size: 16px;
      width: 180px;
      letter-spacing:1px;
      cursor: pointer;
      transition-duration: 0.4s;
    }
    #invia:hover{
      background-color: #19971e;
    }

    #cancella{
      background:none;
      border:none;
      margin-right: 20px;;
      font-weight:bold;
      padding:2px 0;
      text-decoration:underline;
      color:#10508c;
      font-size:1em;
      width: auto;
      cursor: pointer;
    }
  }
  .errore{
    color: #B80C14;
  }

}

.postilla{font-size:0.8em; color:#555;margin-top:5px}



#contenuti-sott{
  .schedaRisultato {
    p{
      margin:5px 0;
      background:#fafafa;
      padding:3px 8px ;
      span.title {
        float:left;
        width:12em;
      }
      span.postilla{
        font-size:0.8em;
      }
      strong {
        font-size:1.1em;
      }
    }

  }
}


#fototransito {
  background:#f6f6f6;
  margin-top:5px;
  padding:10px;
  img {
    width: 100%;
    float:none;
    border-color:#a8caff;
    cursor:pointer;
    cursor:-moz-zoom-in;
  }
  img:hover {
    border-color:#478fff;
  }
}
.solostampa{display: none}
